<template>
  <div
    class="composant-numero-festival"
    :class="numero.verrouillageNumero ? 'flex-line' : 'flex-column'"
  >
    <div class="ligne-titre">
      <div class="frame-informations" @click="toggleVerrouillageNumero">
        <div class="fold-et-titre">
          <img class="img-fold" :src="imgIconeFold" alt="Ouvrir/Fermer" />
          <div class="titre-numero">{{ numero.numero_numero }} - {{ numero.titre_numero }}</div>
        </div>
        <div class="info-saisie">
          <div class="statut-saisie" :title="detailsIcone">
            {{ statutSaisie() }}
          </div>
        </div>
      </div>
      <div class="croix-suppression" @click="supprimerNumero" v-if="festivalCloture != 1">
        <i class="fa-solid fa-trash-can"></i>
      </div>
    </div>
    <div v-if="!numero.verrouillageNumero" class="frame-saisie-stock">
      <div class="lieu-saisie">{{ numero.nom_lieu }} - Stock de {{ momentLabel }}</div>
      <div class="frame-composants-saisie-stock">
        <ComposantSaisieStock :labelType="true" :estUnPack="detailsSaisie.estUnPack"/>
        <ComposantSaisieStock
          v-model="detailsSaisie"
          :estUnPack="detailsSaisie.estUnPack"
          typeSaisie="Stock"
          @update="() => update()"
          :checkVerrouillage="checkVerrouillage.bind(this)"
          :festivalCloture="festivalCloture"
          :displayLock="!festivalCloture"
        />
        <!-- <div v-else>
          <ComposantSaisieStock
            v-model="detailsSaisieVide"
            typeSaisie="Stock"
            @update="() => update()"
            :checkVerrouillage="checkVerrouillage.bind(this)"
            :festivalCloture="festivalCloture"
            :displayLock="!festivalCloture"
          /> -->
          <!-- <ComposantSaisieStock
            v-model="detailsSaisie"
            typeSaisie="Stock"
            @update="() => update()"
            :checkVerrouillage="checkVerrouillage.bind(this)"
            :festivalCloture="festivalCloture"
            :displayLock="!festivalCloture"
          /> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "ComposantNumeroFestival",
  components: { ComposantSaisieStock: () => import("./composantSaisieStock") },
  props: {
    festivalCloture: { default: false },
    updateListeLieuxUtilises: {
      type: Function,
      default: () => {
        console.error(
          "ComposantNumeroFestival : Aucune fonction d'update lieux utilisés transmise"
        );
      },
    },
    updateNumero: {
      type: Function,
      default: () => {
        console.error("ComposantNumeroFestival : Aucune fonction d'update de numéro");
      },
    },

    numero: {
      default: {
        numero_numero: "#",
        titre_numero: "Aucun numéro spécifié",
        nom_lieu: "lieu non spécifié",
      },
    },
  },
  data: () => {
    return {
      // stockTotal: 0,
      detailsSaisie: undefined,
      detailsSaisieVide: undefined,
      detailsSaisiePlein: undefined,
      NOMBRE_EMMENES: "NOMBRE_EMMENES",
      NOMBRE_RAMENES: "NOMBRE_RAMENES",
      detailsIcone: "",
      // `Statut saisie
      //   ❌ : saisie à faire
      //   ✔️ : toutes les saisies ont été effectuées`,
      //   🕒 : saisie après retour à faire
    };
  },
  computed: {
    ...mapState(["imgLivre", "imgLivreOuvert", "UNITE_FESTIVAL_DEPART", "UNITE_FESTIVAL_RETOUR"]),
    momentLabel() {
      return this.numero.type_unite_festival == this.UNITE_FESTIVAL_DEPART ? "départ" : "retour";
    },
    imgIconeFold() {
      return this.numero.verrouillageNumero ? this.imgLivre : this.imgLivreOuvert;
    },
    stockTotal() {
      return this.detailsSaisie.estUnPack ?
        parseInt(this.detailsSaisie.pack_plein_unite_festival) + parseInt(this.detailsSaisie.pack_vide_unite_festival)
        : this.detailsSaisie.stockTotal
    }
  },
  methods: {
    ...mapActions(["phpPost", "modaleAlert"]),
    ...mapMutations(["ajouterRequete", "supprimerRequete"]),
    supprimerNumero() {
      if (this.detailsSaisie.verrouillage_unite_festival == 1)
        return this.modaleAlert(
          "Impossible de retirer un numéro verrouillé de la liste. Pour le retirer, vous devez d'abord déverrouiller la saisie."
        );
      this.$emit("suppression");
    },
    toggleVerrouillageNumero() {
      this.$emit("setVerrouillage", !this.numero.verrouillageNumero);
    },
    statutSaisie() {
      let uniteFestival = this.detailsSaisie;
      if (uniteFestival != undefined) {
        let labelPrecision =
          uniteFestival.type_unite_festival == this.UNITE_FESTIVAL_DEPART
            ? ["avant départ à faire", "Emmenés : "]
            : ["retour à faire", "Ramenés : "];

        return uniteFestival.verrouillage_unite_festival == 0
          ? "❓ Saisie " + labelPrecision[0]
          : "✔️ Terminé - " + labelPrecision[1] + this.stockTotal;
        // return "🕒 En cours - Emmenés : " + nbreEmmenes.stockTotal;
      } else return "❓";
    },
    update() {
      let sendNum = {
        ...this.numero,
        ...this.detailsSaisie,
      };
      this.phpPost({
        phpFile: "assets/php/modifierUniteFestival.php",
        sendObject: sendNum,
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.updateListeLieuxUtilises();
            this.updateNumero(sendNum);
            this.sauvegardeDetailsSaisie = { ...this.detailsSaisie };
          } else {
            this.detailsSaisie = { ...this.sauvegardeDetailsSaisie };
          }
        },
      });
    },
    checkVerrouillage() {
      // if (this.detailsSaisie.verrouillage_unite_festival == 1) {
      //   // this.$emit("setVerrouillage", true);
      //   this.toggleVerrouillageNumero();
      // }
    },
    resetDetailsSaisie() {
      // if (this.numero.estUnPack) {
      //   this.detailsSaisieVide = { ...this.numero };
      //   this.detailsSaisiePlein = { ...this.numero };
      // } else {
        this.sauvegardeDetailsSaisie = { ...this.numero };
        this.detailsSaisie = { ...this.numero };
        this.detailsSaisie.stockTotal =
          parseInt(this.detailsSaisie.cartons_unite_festival) * 150 +
          parseInt(this.detailsSaisie.ramettes_unite_festival) * 50 +
          parseInt(this.detailsSaisie.libres_unite_festival);
      // }
    },
  },
  mounted: function() {
    this.resetDetailsSaisie();
  },
  watch: {
    numero() {
      this.resetDetailsSaisie();
      // this.detailsSaisie.verrouillageNumero = this.numero.verrouillageNumero;
      // this.detailsSaisie = { ...this.numero };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-numero-festival {
  // background-color: $couleur-fond-menu-navigation;
  background-color: white;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  &.flex-column {
    flex-direction: column;
  }
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  border: 1px solid $couleur-texte-clair;
  border-radius: 10px;
  padding: 5px;
  @media (max-width: $mobile-break-point) {
    padding: 5px 10px;
  }
  // margin-right: 20px;
}

.ligne-titre {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.frame-informations {
  // border: 1px solid black;
  display: flex;
  cursor: pointer;
  @media (max-width: $mobile-break-point) {
    flex-direction: column;
    width: 100%;
  }
}
.fold-et-titre {
  display: flex;
  @media (max-width: $mobile-break-point) {
    margin-bottom: 5px;
  }
}

.img-fold {
  height: 17px;
  margin-right: 5px;
}

.titre-numero {
  width: 220px;
  @include typo-festival;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: $mobile-break-point) {
    margin-right: 0px;
  }
  margin-right: 40px;
  // justify-content: left;
}

.statut-saisie {
  @include typo-festival;
  position: relative;
  bottom: 4px;
}

.croix-suppression {

  font-size: 15px;
  border-radius: 3px;
  position: relative;
  bottom: 1px;
  padding: 5px;
  margin-left: 15px;
  
    color: rgb(255, 65, 65);
    background-color: white;
  @media (max-width: $mobile-break-point) {
    font-size: 1.2em;
  }

  &:hover {
    // border: none;
    transition-property: background-color;
    transition-duration: 300ms;
    border-radius: 15px;
    
    
    color: white;
    background-color: rgb(255, 65, 65);
    
    cursor: pointer;
  }
}

.frame-saisie-stock {
  display: flex;
  flex-direction: column;
  border: 1px solid $couleur-texte-fonce;
  box-shadow: 0px 5px 10px $opacity-25;
  padding: 10px;
  border-radius: 10px;
  // width: 100%;
  // justify-content: space-around;
  margin-top: 15px;
}
.frame-composants-saisie-stock {
  margin: 0 auto;
}
.lieu-saisie {
  text-align: center;
  margin-bottom: 5px;
}

@media (max-width: $mobile-break-point) {
}
</style>
